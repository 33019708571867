import {Form, useSearchParams} from '@remix-run/react';
import {useProductDetails} from '~/useProductDetails';
import {formatMoney} from '~shared/utils/format';
import {isPreviewing, RegisteredComponent} from '@builder.io/sdk-react';
import {Button} from '~/components/ui/Button';
import Spinner from '~/components/ui/Spinner';
import {styled, VStack} from '@styled-system/jsx';

type OneClickUpsellProps = {
  productId: string
  buttonText: string
  tags: { tag: string }[]
}

const OneClickUpsell = ({productId, buttonText, tags}: OneClickUpsellProps) => {

  const [searchParams] = useSearchParams();
  const stepId = searchParams.get('stepId');
  const productDetails = useProductDetails(productId ? [productId] : []);

  if (!stepId && !isPreviewing()) {
    return <>
      <p>Error loading button.</p>
    </>
  }

  if (!Object.keys(productDetails).length && !isPreviewing) {
    return <Spinner/>;
  }

  const details = productDetails[productId];
  return (
    <Form method="post" action="/one-click" reloadDocument>
      <input type="hidden" name="productId" value={productId}/>
      <input type="hidden" name="stepId" value={stepId || undefined}/>
      <input type="hidden" name="tags" value={JSON.stringify(tags?.map(t => t.tag) ?? [])}/>
      <VStack gap={0}>
        <Button
          width="full"
          type="submit"
          name="action"
          value="accept"
          whiteSpace="initial"
          flexDirection="column"
          height="auto"
          p={3}
          mb={2}
        >
          <styled.span fontSize="lg" fontWeight="bold">{buttonText}</styled.span>
          {details && !details?.isSubscription && (
            <styled.span fontWeight="medium" fontSize="xs">Clicking here will
              add {formatMoney(details?.price)} to
              your order!</styled.span>
          )}
        </Button>
        <Button
          variant="link"
          width="full"
          type="submit"
          name="action"
          value="skip"
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          No Thanks, I'll Pass!
        </Button>
      </VStack>
    </Form>
  )
    ;
};

const component: RegisteredComponent = {
  component: OneClickUpsell,
  name: 'One Click Upsell',
  inputs: [
    {name: 'productId', type: 'string', required: true},
    {name: 'buttonText', type: 'string', required: true, defaultValue: 'Buy it now'},
    {
      name: 'tags', type: 'list', defaultValue: [], subFields: [
        {name: 'tag', type: 'string', required: true},
      ]
    }
  ]
};

export default component;
