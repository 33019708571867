import {useEffect, useState} from 'react';

export type ProductDetails = {
  title: string
  imageUrl: string
  price: string
  hasShipping: boolean
  isSubscription: boolean
}

export const useProductDetails = (productIds: string[]) => {
  const [productDetails, setProductDetails] = useState<Record<string, ProductDetails>>({});

  useEffect(() => {
    if (!productIds.length) {
      return;
    }

    const params = new URLSearchParams();
    productIds.forEach(id => params.append('productId', id));

    setProductDetails({});
    fetch('/product-details?' + params.toString())
      .then(async (response) => {
        setProductDetails(await response.json());
      })
  }, [JSON.stringify(productIds)]); // eslint-disable-line react-hooks/exhaustive-deps

  return productDetails;
}
