import {RegisteredComponent} from '@builder.io/sdk-react';
import {Box, Stack, styled, VStack} from '@styled-system/jsx';

type ProductImagesProps = {
  images: {
    image: string
    title?: string
    caption?: string
  }[]
}

const ProductImages = ({images}: ProductImagesProps) => {
  if (images.length == 0) {
    return null;
  }
  const [firstImage, ...rest] = images;

  return <VStack>
    <Box position="relative">
      <VStack position="absolute" bottom="5" left="5" color="white" alignItems="flex-start">
        {firstImage.title?.split('\n')?.map((caption, i) => (
          <Box
            key={i}
            bgColor="primary"
            p={1}
            fontFamily="heading"
            textTransform="uppercase"
            fontSize="xl"
            fontWeight="bold"
            hideBelow="sm"
          >
            {caption}
          </Box>
        ))}
      </VStack>
      <styled.img
        src={firstImage.image}
        width="full"
        rounded="lg"
      />
    </Box>
    {firstImage.caption?.replace(/(<([^>]+)>)/gi, "")?.trim() && <VStack alignItems="flex-start" dangerouslySetInnerHTML={{__html: firstImage.caption}}/>}
    <Stack direction={{base: 'column', sm: 'row'}}>
      {rest.map(image => (
        <VStack key={image.image} alignItems="stretch">
          <Box position="relative">
            {image.title?.replace(/(<([^>]+)>)/gi, "")?.trim() && (
              <VStack position="absolute" bottom="5" left="5" color="white" alignItems="flex-start">
                {image.title?.split('\n')?.map((caption, i) => (
                  <Box
                    key={i}
                    bgColor="primary"
                    p={1}
                    fontFamily="heading"
                    textTransform="uppercase"
                    fontSize="xl"
                    fontWeight="bold"
                    hideBelow="md"
                  >
                    {caption}
                  </Box>
                ))}
              </VStack>
            )}
            <styled.img
              src={image.image}
              rounded="lg"
            />
          </Box>
          {image.caption?.replace(/(<([^>]+)>)/gi, "")?.trim() && <VStack alignItems="flex-start" dangerouslySetInnerHTML={{__html: image.caption}}/>}
        </VStack>
      ))}
    </Stack>
  </VStack>
}

const component: RegisteredComponent = {
  component: ProductImages,
  name: 'Product Image',
  inputs: [
    {
      name: 'images', type: 'list', defaultValue: [], subFields: [
        {name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'], required: true},
        {name: 'title', type: 'longText', required: false},
        {name: 'caption', type: 'richText', required: false},
      ]
    }
  ]
}

export default component;
